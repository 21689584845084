<div class="row">
    <div class="col-md-12">
        <div id="carouselExampleIndicators" class="carousel slide" data-ride="carousel">           
            <div class="carousel-inner">
                <div class="carousel-item" *ngFor="let item of carousel; let i = index" [ngClass]="{'active': i === 0}">
                    <img class="w-100" src="{{rootUrl + item.linkurl}}" alt="{{item.imagetitle_en}}" style="height:380px;width: auto;">
                    <div class="carousel-caption">
                        <h6 *ngIf="cms.lang.value=='English'" [ngStyle]="{'font-size': cms.fontsize.value +'px'}">
                            {{item.imagetitle_en}}</h6>
                        <h6 *ngIf="cms.lang.value=='Hindi'" [ngStyle]="{'font-size': cms.fontsize.value +'px'}">
                            {{item.imagetitle_hn}}</h6>
                    </div>
                </div>
            </div>
            <a class="carousel-control-prev" href="#carouselExampleIndicators" role="button" data-slide="prev">
                <span class="carousel-control-prev-icon" aria-hidden="true"></span>
                <span class="sr-only">Previous</span>
            </a>
            <a class="carousel-control-next" href="#carouselExampleIndicators" role="button" data-slide="next">
                <span class="carousel-control-next-icon" aria-hidden="true"></span>
                <span class="sr-only">Next</span>
            </a>
        </div>        
    </div>

</div>

<main id="main-content">    
    <div class="" id="b-homedb" [ngStyle]="{'color': cms.colorcode.value, 'background-color': cms.colorbackcode.value,'font-size': cms.fontsize.value +'px'}">   
    <!-- {{now | date:'EEEE, MMMM d, y, h:mm:ss a'}} -->
        <div class="">
            <div class="">
                <div class="ticker">
                    <div class="title"
                        [ngStyle]="{'color': cms.colorcode.value, 'background-color': cms.colorfront.value,'font-size': cms.fontsize.value +'px'}">
                        <h5 [ngStyle]="{'font-size': cms.fontsize.value +'px'}">{{'HOME.Important' | translate}} </h5>
                    </div>
                    <div class="news" [ngStyle]="{'color': cms.colorcode.value, 'background-color': cms.colorfrontblack.value}">
                        <marquee onmouseover="stop()" onmouseout="start()" class="news-content">
                            <p [ngStyle]="{'color': cms.colorcode.value, 'font-size': cms.fontsize.value +'px'}">
                                {{'HOME.OldPortal1' | translate}} 
                                <!-- <a
                                    [ngStyle]="{'color': cms.colorcode.value, 'font-size': cms.fontsize.value +'px'}"
                                    href="../../assets/pdf/tender010124.pdf" target=" _blank"
                                    > &nbsp;&nbsp; {{'HOME.OldPortal2' | translate}} <span class="blink"> New </span> </a> -->
                                </p>
                        </marquee>
                    </div>
                </div>
            </div>
        </div>  
    </div>
    <div class="my-5" id="b-homedb">
        <div class="container-fluid">
            <div class="row">
                <div class="col-md-9">
                    <div class="text-white">
                        <div class="card-header text-center"
                            [ngStyle]="{'color': cms.colorcodeblack.value, 'font-size': cms.fontsize.value +'px'}">
                            <img src="../../assets/images/logo/sdg.png" alt="" style="height: 25px;width: 25px;">
                            {{'ABOUTSPC.AboutSpc' |
                            translate}}
                        </div>
                        <div class="card-body">
                            <div class="row ">
                                <div class="col-md-4">
                                    <div class="our-team live-box" style="padding-top: 20px;">
                                        <div class="picture">
                                            <img class="img-fluid" src="{{rootUrl + deptdata[0]?.dept_min_pic}}" title="{{deptdata.dept_minister_name_en}}" style="height:140px; width: auto;">
                                        </div>
                                        <div class="team-content"
                                            [ngStyle]="{'color': '#000000', 'background-color': ''}">
                                            <a href="#" target="_blank"
                                                [ngStyle]="{'color': '#000000','font-size': cms.fontsize.value +'px'}" class="name"
                                                *ngIf="cms.lang.value=='English'">
                                                {{deptdata[0]?.dept_minister_name_en}}
                                                <br>  {{deptdata[0]?.dept_minister_post_en}} <br> Chairman, Rajya NITI Aayog, Chhattisgarh, </a>
                                            <a href="#" target="_blank"
                                                [ngStyle]="{'color': '#000000','font-size': cms.fontsize.value +'px'}" class="name"
                                                *ngIf="cms.lang.value=='Hindi'">
                                                {{deptdata[0]?.dept_minister_name_hn}} <br> {{deptdata[0]?.dept_minister_post_hn}} 
                                                <br> अध्यक्ष, राज्य नीति आयोग, छत्तीसगढ़
                                            </a>
                                        </div>
                                    </div>                                
                                </div>
                                <div class="col-md-4">
                                    <div class="our-team live-box" style="padding-top:20px;">
                                        <div class="picture">
                                            <img class="img-fluid" src="{{rootUrl + deptdata[0]?.ms_pic_url}}" title="{{deptdata.ms_name_en}}" style="height:140px; width:140px;">
                                        </div>
                                        <div class="team-content" [ngStyle]="{'color':  '#000000', 'background-color': ''}">
                                            <a href="#" target="_blank"
                                                [ngStyle]="{'color':  '#000000','font-size': cms.fontsize.value +'px'}" class="name"
                                                *ngIf="cms.lang.value=='English'">
                                                {{deptdata[0]?.ms_name_en}} <br> {{deptdata[0]?.ms_post_en}}</a>
                                            <a href="#" target="_blank"
                                                [ngStyle]="{'color':  '#000000','font-size': cms.fontsize.value +'px'}" class="name"
                                                *ngIf="cms.lang.value=='Hindi'">
                                                {{deptdata[0]?.ms_name_hn}} <br>{{deptdata[0]?.ms_post_hn}}                                               
                                            </a>
                                        </div>
                                    </div>
                                </div>                                                      
                                <div class="col-md-4">
                                    <div class="our-team live-box" style="padding-top: 20px; ">
                                        <div class="picture">
                                            <img class="img-fluid" src="{{rootUrl + deptdata[0]?.vc_pic_url}}" title="{{deptdata.vc_name_en}}" style="height:140px; width: auto;">
                                        </div>
                                        <div class="team-content"
                                            [ngStyle]="{'color':  '#000000', 'background-color': ''}">
                                            <a href="#" target="_blank"
                                                [ngStyle]="{'color':  '#000000','font-size': cms.fontsize.value +'px'}" class="name"
                                                *ngIf="cms.lang.value=='English'">
                                                {{deptdata[0]?.vc_name_en}} <br> {{deptdata[0]?.vc_post_en}} 
                                                <br>
                                                Hon'ble Vice Chairman , Rajya NITI Aayog, Chhattisgarh
                                            </a>
                                            <a href="#" target="_blank"
                                                [ngStyle]="{'color':  '#000000','font-size': cms.fontsize.value +'px'}" class="name"
                                                *ngIf="cms.lang.value=='Hindi'">
                                                {{deptdata[0]?.vc_name_hn}} <br> {{deptdata[0]?.vc_post_hn}}
                                                <br>
                                                माननीय उपाध्यक्ष, राज्य नीति आयोग, छत्तीसगढ़ 
                                            </a>
                                        </div>
                                    </div>                               
                                </div>                      
                            </div>    
                            <br>
                            <div class="row">
                                <div class="col-md-12" style="padding-top:-20px;">
                                    <p [ngStyle]="{'color': cms.colorcodeblack.value,'font-size': cms.fontsize.value +'px'}" class="text-justify">{{'ABOUTSPC.Details'|
                                        translate}}</p>
                                    <a [ngStyle]="{'color': cms.colorcodeblack.value,'font-size': cms.fontsize.value +'px'}" routerLink='/aboutus'>
                                        {{'HOME.ViewMore' | translate}}</a>
                                </div>
                            </div>                   
                        </div>
                    </div>
                </div>
                <div class="col-md-3">
                    <div class="card" style="background-color: #f8f9fa;">
                        <div class="card-header text-center"
                            [ngStyle]="{'background-color': cms.colorfront.value,'color': cms.colorcode.value,'font-size': cms.fontsize.value +'px'}">
                            <img src="../../assets/images/logo/noticeboard.png" alt="" style="height: 25px;width: 25px;"> <span> {{'NOTICEBOARD.NoticeBoard' | translate}}
                            </span>
                        </div>
                        <ul class="list-group">
                            <marquee direction="up" height="300" scrollamount="5" behavior="" onmouseover="stop()" onmouseout="start()">
                                <div *ngFor="let t of noticeboard">                       
                                    <li class="list-group-item"
                                        [ngStyle]="{'font-size': cms.fontsize.value +'px', 'color': cms.colorfront.value}" *ngIf="cms.lang.value=='Hindi'">
                                        <a [ngStyle]="{'font-size': cms.fontsize.value +'px', 'color': cms.colorfront.value}"
                                            style="cursor: pointer;" target="_blank" routerLink='/noticeboard'>
                                            {{t.linknamehn}}
                                            {{t.issuedate}}
                                        </a>
                                        <ng-container *ngIf="t.status == 'N'">
                                            <span class="blink">नया</span>
                                        </ng-container>                                        
                                    </li>
                                    <li class="list-group-item" [ngStyle]="{'font-size': cms.fontsize.value +'px', 'color': cms.colorfront.value}"
                                        *ngIf="cms.lang.value=='English'">
                                        <a [ngStyle]="{'font-size': cms.fontsize.value +'px', 'color': cms.colorfront.value}" style="cursor: pointer;"
                                            target="_blank" routerLink='/noticeboard'>
                                            {{t.linknameen}}
                                            {{t.issuedate}}
                                        </a>
                                        <ng-container *ngIf="t.status == 'N'">
                                            <span class="blink">New</span>
                                        </ng-container>
                                    </li>
                                </div>
                            </marquee>
                            <a [ngStyle]="{'color': cms.colorfront.value,'font-size': cms.fontsize.value +'px'}"
                             routerLink='/noticeboard'>
                            {{'HOME.ViewMore' | translate}}</a>
                        </ul>
                    </div>
                </div>                
            </div>
        </div>
    </div>
    
    <div class="container-fluid">
        <div class="row my-3">
            <div class="col-md-12">
                <div class="card-header text-center"
                    [ngStyle]="{'color': cms.colorcodeblack.value, 'font-size': cms.fontsize.value +'px'}">
                    <img src="../../assets/images/logo/activities.png" alt="" style="height: 25px;width: 25px;">
                    {{'ABOUTSPC.AboutSpc1' |
                    translate}}
                </div>
                <br>
            </div>           
            <div class="col-md-3">
                <div class="card" style="background-color: #DDE6ED; height: 400px;width: auto;">
                    <div class="card-header text-center"
                        [ngStyle]="{'color': cms.colorcode.value, 'background-color': cms.colorfront.value,'font-size': cms.fontsize.value +'px'}">
                        <img src="../../assets/images/logo/establisment.png" alt="" style="height: 20px;width: 20px;">
                        {{'OTHERACTIVITE.OtherActivite' | translate}}
                    </div>
                    <div class="card-body">
                        <marquee direction="up" height="300" scrollamount="5" behavior="" onmouseover="stop()" onmouseout="start()">
                            <div *ngFor="let t of others">
                                <li class="list-group-item"
                                    [ngStyle]="{'font-size': cms.fontsize.value +'px', 'color': cms.colorfront.value}"
                                    *ngIf="cms.lang.value=='Hindi'">
                                    <!-- <a [ngStyle]="{'font-size': cms.fontsize.value +'px', 'color': cms.colorfront.value}"
                                        style="cursor: pointer;" target="_blank" [routerLink]="'/tendernoticeboard/' + t.document_type">
                                        {{t.document_type_det}}
                                        {{t.update_date | date :'mediumDate'}}
                                    </a> -->
                                    <a [ngStyle]="{'font-size': cms.fontsize.value +'px', 'color': cms.colorfront.value}"
                                                                            style="cursor: pointer;" target="_blank" [routerLink]="'/viksitcg'">
                                                                            {{t.document_type_det}}
                                                                            {{t.update_date | date :'mediumDate'}}
                                                                        </a>
                                </li>
                                <li class="list-group-item"
                                    [ngStyle]="{'font-size': cms.fontsize.value +'px', 'color': cms.colorfront.value}"
                                    *ngIf="cms.lang.value=='English'">
                                    <!-- <a [ngStyle]="{'font-size': cms.fontsize.value +'px', 'color': cms.colorfront.value}"
                                        style="cursor: pointer;" target="_blank" [routerLink]="'/tendernoticeboard/' + t.document_type">
                                        {{t.document_type_det}}
                                        {{t.update_date | date :'mediumDate'}}
                                    </a> -->
                                    <a [ngStyle]="{'font-size': cms.fontsize.value +'px', 'color': cms.colorfront.value}"
                                                                            style="cursor: pointer;" target="_blank" [routerLink]="'/viksitcg'">
                                                                            {{t.document_type_det}}
                                                                            {{t.update_date | date :'mediumDate'}}
                                        </a>
                                </li>
                            </div>
                        </marquee>
                        <!-- <a [ngStyle]="{'color': cms.colorfront.value,'font-size': cms.fontsize.value +'px'}"
                           [routerLink]="'/tendernoticeboard/0'">
                            {{'HOME.ViewMore' | translate}}</a> -->
                        <a [ngStyle]="{'color': cms.colorfront.value,'font-size': cms.fontsize.value +'px'}"
                                                   [routerLink]="'/viksitcg'">
                                                    {{'HOME.ViewMore' | translate}}</a>
                    </div>
                </div>
            </div>
            <div class="col-md-3">
                <div class="card" style="background-color: #DDE6ED; height: 400px;width: auto;">
                    <div class="card-header text-center"
                        [ngStyle]="{'color': cms.colorcode.value, 'background-color': cms.colorfront.value,'font-size': cms.fontsize.value +'px'}">
                        <img src="../../assets/images/logo/sdg1.png" alt="" style="height: 20px;width: 20px;">
                        {{'REPORT.Reporttile' | translate}}
                    </div>
                    <ul class="list-group">
                        <marquee direction="up" height="300" scrollamount="5" behavior="" onmouseover="stop()" onmouseout="start()">
                            <div *ngFor="let t of reports">
                                <li class="list-group-item"
                                    [ngStyle]="{'font-size': cms.fontsize.value +'px', 'color': cms.colorfront.value}"
                                    *ngIf="cms.lang.value=='Hindi'">
                                    <a [ngStyle]="{'font-size': cms.fontsize.value +'px', 'color': cms.colorfront.value}"
                                        style="cursor: pointer;" target="_blank" [routerLink]="'/sdgnoticeboard/' + t.document_type">
                                        {{t.document_type_det}}
                                        {{t.update_date | date :'mediumDate'}}
                                    </a>
                                </li>
                                <li class="list-group-item"
                                    [ngStyle]="{'font-size': cms.fontsize.value +'px', 'color': cms.colorfront.value}"
                                    *ngIf="cms.lang.value=='English'">
                                    <a [ngStyle]="{'font-size': cms.fontsize.value +'px', 'color': cms.colorfront.value}"
                                        style="cursor: pointer;" target="_blank" [routerLink]="'/sdgnoticeboard/' + t.document_type">
                                        {{t.document_type_det}}
                                        <!-- {{t.update_date}} -->
                                    </a>
                                </li>
                            </div>
                        </marquee>
                    </ul>
                    <a [ngStyle]="{'color': cms.colorfront.value,'font-size': cms.fontsize.value +'px'}" [routerLink]="'/sdgnoticeboard/0'">
                        {{'HOME.ViewMore' | translate}}</a>
                </div>
            </div>
            <div class="col-md-3">
                <div class="card" style="background-color: #DDE6ED; height: 400px;width: auto;">
                    <div class="card-header text-center" [ngStyle]="{'color': cms.colorcode.value, 'background-color': cms.colorfront.value,'font-size': cms.fontsize.value +'px'}">
                        <img src="../../assets/images/logo/mou.png" alt="" style="height: 18px;width: 18px;">
                            {{'PUBLICATION.Publication' | translate}}
                    </div>
                    <ul class="list-group">
                        <marquee direction="up" height="300" scrollamount="5" behavior="" onmouseover="stop()" onmouseout="start()">
                            <div *ngFor="let t of publication">
                                <li class="list-group-item"
                                    [ngStyle]="{'font-size': cms.fontsize.value +'px', 'color': cms.colorfront.value}"
                                    *ngIf="cms.lang.value=='Hindi'">
                                    <a [ngStyle]="{'font-size': cms.fontsize.value +'px', 'color': cms.colorfront.value}"
                                        style="cursor: pointer;" target="_blank" [routerLink]="'/mounoticeboard/' + t.document_type">
                                        {{t.document_type_det}}
                                        {{t.update_date | date :'mediumDate'}}
                                    </a>
                                </li>
                                <li class="list-group-item"
                                    [ngStyle]="{'font-size': cms.fontsize.value +'px', 'color': cms.colorfront.value}"
                                    *ngIf="cms.lang.value=='English'">
                                    <a [ngStyle]="{'font-size': cms.fontsize.value +'px', 'color': cms.colorfront.value}"
                                        style="cursor: pointer;" target="_blank" [routerLink]="'/mounoticeboard/' + t.document_type">
                                        {{t.document_type_det}}
                                        {{t.update_date | date :'mediumDate'}}
                                    </a>
                                </li>
                            </div>
                        </marquee>
                    </ul>
                    <a [ngStyle]="{'color': cms.colorfront.value,'font-size': cms.fontsize.value +'px'}" [routerLink]="'/mounoticeboard/0'">
                        {{'HOME.ViewMore' | translate}}</a>                        
                </div>
            </div>           
            <div class="col-md-3">
                <div class="card" style="background-color: #DDE6ED; height: 400px;width: auto;">
                    <div class="card-header text-center" [ngStyle]="{'background-color': cms.colorfront.value, 'color': cms.colorcode.value,'font-size': cms.fontsize.value +'px'}">
                        <img src="../../assets/images/logo/research.png" alt="" style="height: 20px;width: 20px;">
                        {{'RESEARCH.ResearchProfile' | translate}}</div>
                        <ul class="list-group">
                            <marquee direction="up" height="300" scrollamount="5" behavior="" onmouseover="stop()" onmouseout="start()">
                                <div *ngFor="let t of research">
                                    <li class="list-group-item"
                                        [ngStyle]="{'font-size': cms.fontsize.value +'px', 'color': cms.colorfront.value}"
                                        *ngIf="cms.lang.value=='Hindi'">
                                        <a [ngStyle]="{'font-size': cms.fontsize.value +'px', 'color': cms.colorfront.value}"
                                            style="cursor: pointer;" target="_blank" [routerLink]="'/researchnoticeboard/' + t.document_type">
                                            {{t.document_type_det}}
                                            {{t.update_date | date :'mediumDate'}}
                                        </a>
                                    </li>
                                    <li class="list-group-item"
                                        [ngStyle]="{'font-size': cms.fontsize.value +'px', 'color': cms.colorfront.value}"
                                        *ngIf="cms.lang.value=='English'">
                                        <a [ngStyle]="{'font-size': cms.fontsize.value +'px', 'color': cms.colorfront.value}"
                                            style="cursor: pointer;" target="_blank" [routerLink]="'/researchnoticeboard/' + t.document_type">
                                            {{t.document_type_det}}
                                            {{t.update_date | date :'mediumDate'}}
                                        </a>
                                    </li>
                                </div>
                            </marquee>                       
                        </ul>
                        <a [ngStyle]="{'color': cms.colorfront.value,'font-size': cms.fontsize.value +'px'}" [routerLink]="'/researchnoticeboard/0'">
                            {{'HOME.ViewMore' | translate}}</a>
                </div>
            </div>
        </div>
    </div>

    <!-- <div class="container-fluid">
        <div class="imp-img-cir">         
            <div id="flexCarousel" class="flexslider carousel">
                <owl-carousel-o [options]="customOptions">
                    <ng-template carouselSlide *ngFor="let t of gallery">                       
                        <div class="">
                            <img src="{{rootUrl + t.img_link}}" alt=""  style="height: 200px; width: auto;"/>                            
                            <div class="text-container">
                                <h6 [ngStyle]="{'color': cms.colorfront.value,'font-size': cms.fontsize.value +'px'}"
                                    *ngIf="commonservice.lang.value=='English'">
                                    <a [ngStyle]="{'color': cms.colorfront.value,'font-size': cms.fontsize.value +'px'}"
                                        routerLink='/gallery'>{{t.pic_name}}</a>
                                </h6>
                                <h6 [ngStyle]="{'color': cms.colorfront.value,'font-size': cms.fontsize.value +'px'}"
                                    *ngIf="commonservice.lang.value=='Hindi'">
                                    <a [ngStyle]="{'color': cms.colorfront.value,'font-size': cms.fontsize.value +'px'}" routerLink='/gallery'>
                                        {{t.pic_namehn}} </a>
                                </h6>
                            </div>
                        </div>
                    </ng-template>
                </owl-carousel-o>
            </div>
        </div>
    </div> -->


    <div class="container-xxl py-4">
            <div class="container">
                <!-- <div class="card-header text-center" [ngStyle]="{'color': '#000000', 'font-size': cms.fontsize.value +'px'}">
                    <i class="fa fa-gift" aria-hidden="true"></i>
                    {{'STATESCHEMES.Title' |
                    translate}}
                </div> -->
                
                <!-- <div class="row g-4">
                    <div class="col-md-4">                
                        <div class="pt-3">
                            <div class="card">
                                <div class="image-box text-center">
                                    <img src="{{rootUrl + schemes[0]?.img_link}}" alt="राज्य सरकार की फ्लैगशिप योजना - न्याय योजना" style="height: 270px; width: auto;" /> 
                                    <img src="../../../assets/images/portal/scheme1.png" alt="राज्य सरकार की फ्लैगशिप योजना - न्याय योजना" style="height: 250px; width: auto;" />
                                </div>
                            </div>
                            <br>
                            <a routerLink='/scheme'>
                            <p class="text-primary mb-0">{{'STATESCHEMES.scheme1' | translate}}</p> </a>
                        </div>
                    </div>
                    <div class="col-md-4">
                        <div class="pt-3">
                            <div class="card">
                                <div class="image-box text-center">
                                    <img src="../../../assets/images/portal/scheme2.png" alt="राज्य सरकार की फ्लैगशिप योजना"
                                        style="height: 250px; width: auto;" />
                                </div>
                            </div>
                            <br>
                            <a routerLink='/scheme'>
                            <p class="text-primary mb-0">{{'STATESCHEMES.scheme2' | translate}}</p> </a>
                        </div>
                    </div>
                    <div class="col-md-4">
                        <div class="pt-3">
                        <div class="card">
                            <div class="image-box  text-center">
                                <img src="../../../assets/images/portal/scheme3.png" alt="राज्य सरकार की फ्लैगशिप योजना"
                                    style="height: 250px; width: auto;" />
                            </div>
                        </div>
                            <br>
                            <a routerLink='/scheme'>
                            <p class="text-primary mb-0">{{'STATESCHEMES.scheme3' | translate}}</p> </a>
                        </div>
                    </div>
                </div> -->
                <div class="row g-4">
                    <div class="col-md-4">
                        <div class="pt-3">
                            <a routerLink='/plans' class="btn btn-info rounded-pill py-3 px-5 mt-3" [ngStyle]="{'color': cms.colorfront.value,'font-size': cms.fontsize.value +'px'}">{{'STATESCHEMES.button1' | translate}}</a>
                        </div>
                    </div>
                    <div class="col-md-4">
                        <div class="pt-3">
                            <a routerLink='/plans' class="btn btn-warning rounded-pill py-3 px-5 mt-3" [ngStyle]="{'color': cms.colorfront.value,'font-size': cms.fontsize.value +'px'}">{{'STATESCHEMES.button2' | translate}}</a>
                        </div>
                    </div>
                    <div class="col-md-4">
                        <div class="pt-3">
                            <a routerLink='/plans' class="btn btn-success rounded-pill py-3 px-5 mt-3" [ngStyle]="{'color': cms.colorfront.value,'font-size': cms.fontsize.value +'px'}">{{'STATESCHEMES.button3' | translate}}</a>
                        </div>
                    </div>
                </div>
            </div>
    </div>
    <div class="to-top" (click)="scrollToTop()">

    <button [ngStyle]="{'color': cms.colorcode.value, 'background-color': cms.colorfront.value}"
            type="button"
            class="btn btn-success btn-floating btn-sm"
            id="btn-back-to-top"
        
            >
    <i class="fa fa-arrow-up"></i>
    </button>
    </div>   
</main>