export const Menus: any[] = [
    {
        path: '/home',
        title: 'Home',
        titlehi: 'मुख्य पृष्ठ ',
        // icon: 'fa fa-home',
        submenu: []
    },    
    {
        path: '',
        title: 'About Us',
        titlehi: 'हमारे बारे में',
        // icon: 'fa fa-globe',
        submenu: [
            {
                path: '/aboutus',
                title: 'About Rajya Niti Aayog',
                titlehi: 'राज्य नीति आयोग के बारे में',
                icon: '',
                submenu: []
            },
            {
                path: '/teamspc',
                title: 'Executive Board',
                titlehi: 'कार्यकारी बोर्ड',
                icon: '',
                submenu: []
            },
            {
                path: '/whoswho',
                title: 'Administrative Directory',
                titlehi: 'प्रशासनिक निदेशिका',
                icon: '',
                submenu: []
            },
            {
                path: '/organizationalchart',
                title: 'Organization Chart',
                titlehi: 'संगठनात्मक चार्ट',
                icon: '',
                submenu: []
            }
        ]
    },
    {
        path: '',
        title: 'Activities',
        titlehi: 'गतिविधियॉ',
        // icon: 'fa fa-institution',
        submenu: [
            {
                path: '/tendernoticeboard/0',
                title: 'Establishment and Coordination',
                titlehi: 'स्थापना एवं समन्वय',               
                icon: '',
                submenu: []
            },
            {
                path: '/sdgnoticeboard/0',
                title: 'SDGs and Projects',
                titlehi: 'एसडीजी एवं परियोजनाओ से सम्बंधित',
                icon: '',
                submenu: []
            },
            {
                path: '/mounoticeboard/0',
                title: 'MOU and Coordination with other departments',
                titlehi: 'एमओयू एवं अन्य विभागों से समन्वय सम्बंधित',
                icon: '',
                submenu: []
            },
            {
                path: '/researchnoticeboard/0',
                title: 'Research',
                titlehi: 'शोध अध्ययन',
                icon: '',
                submenu: []
            },
            {
                path: '/taskforce',
                title: 'Task Force and Working Group',
                titlehi: 'टास्कफोर्स और कार्यदल',
                icon: '',
                submenu: []
            } ,
            {
                path: '/achivements',
                title: 'Achivements',
                titlehi: 'उपलब्धियाँ',
                icon: '',
                submenu: []
            },
            {
                path: '/plans',
                title: 'Plans and Programme',
                titlehi: 'कार्यक्रम और योजनाएं',
                icon: '',
                submenu: []
            },
            {
                path: '/otheractivities',
                title: 'Other Activities',
                titlehi: 'अन्य गतिविधियाँ',
                icon: '',
                submenu: []
            }          
        ]
    },
    {
        path: '',
        title: 'SDG Dashboard',
        titlehi: 'SDG डैशबोर्ड',    
        // icon: 'fa fa-support',
            submenu: [
            {
                    path: '/sdg',
                    title: 'SDG Dashboard',
                    titlehi: 'SDG डैशबोर्ड',
                    icon: '',
                    submenu: []
            },
        ]
    },  
    {
        path: '',
        title: 'Navonmesh Portal',
        titlehi: 'नवोन्मेष पोर्टल',
        // icon: 'fa fa-support',
        submenu: [
            {
                path: '/bestpractice',
                title: 'Navonmesh Portal',
                titlehi: 'नवोन्मेष पोर्टल',
                icon: '',
                submenu: []
            },
        ]
    },   
    // {
    //     path: '',
    //     title: 'Important Links',
    //     titlehi: 'महत्वपूर्ण लिंक ',
    //     pathMatch: "full" ,
    //     submenu: [   
    //         {
    //             path: '/sdg/1',
    //             title: 'Navonmesh Portal',
    //             titlehi: 'SDG डैशबोर्ड',
    //             icon: '',
    //             submenu: []
    //         },
    //         {
    //             path: '/sdg/2',
    //             title: 'Navonmesh Portal',
    //             titlehi: 'नवोन्मेष पोर्टल',
    //             icon: '',
    //             submenu: []
    //         } 
    //         // {
    //         //     path: '/sdg/3',
    //         //     title: 'Viksit Chhattisgarh',
    //         //     titlehi: 'मोर सपना, मोर विकसित छत्तीसगढ़',
    //         //     icon: '',
    //         //     submenu: []
    //         // }             
    //     ]
    // },
    {
        path: '',
        title: 'Acts & Rules',
        titlehi: 'अधिनियम और नियम',
        // icon: 'fa fa-support',
        submenu: [
            {
                path: '/reports',
                title: 'Acts & Rules',
                titlehi: 'अधिनियम और नियम',
                icon: '',
                submenu: []
            },

        ]
    },  
    {
        path: '',
        title: 'Noticeboard',
        titlehi: 'सूचना पट ',
        //icon: 'fa fa-graduation-cap',
        submenu: [
            {
                path: '/noticeboard',
                title: 'Noticeboard',
                titlehi: 'सूचना पट ',
                icon: '',
                submenu: []
            },
            //  {
            //      path: '/noticeboard',
            //     title: 'Tender',
            //      titlehi: 'निविदाएं',
            //     icon: '',
            //     submenu: []
            // },
            // {
            //     path: '/noticeboard',
            //     title: 'Publications',
            //     titlehi: 'प्रकाशन',
            //     icon: '',
            //     submenu: []
            // },
            // {
            //     path: '/noticeboard',
            //     title: 'Reports',
            //     titlehi: 'रिपोर्ट',               
            //     icon: '',
            //     submenu: []
            // },
            {
                path: '/recruitmentnotice',
                title: 'Recruitment',
                titlehi: 'भर्ती',
                icon: '',
                submenu: []
            }           
        ]
    },    
    {
        path: '',
        title: 'Photogalary',
        titlehi: 'चित्र प्रदर्शनी',
        // icon: 'fa fa-support',
        submenu: [
            {
                path: '/gallery',
                title: 'Photogalary',
                titlehi: 'चित्र प्रदर्शनी',
                icon: '',
                submenu: []
            },

        ]
    },    
    {
        path: '',
        title: 'RTI',
        titlehi: 'सूचना का आधिकार',
        // icon: 'fa fa-support',
        submenu: [
            {
                path: '/rti',
                title: 'RTI',
                titlehi: 'सूचना का आधिकार',
                icon: '',
                submenu: []
            },

        ]
    },
    {
        path: '',
        title: 'Contact Us',
        titlehi: 'हमसे संपर्क करें',
        // icon: 'fa fa-support',
        submenu: [
            {
                path: '/contact-us',
                title: 'Contact Us',
                titlehi: 'हमसे संपर्क करें',
                icon: '',
                submenu: []
            },

        ]
    },
    
];